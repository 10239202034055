<template>
  <transition
    name="slide-left"
    appear
    mode="out-in">
    <div>
      <div
        v-if="isLoadingCourses || isLoadingCategories"
        class="mt-12">
        <v-progress-linear
          indeterminate
          color="primaryColor"></v-progress-linear>
      </div>
      <div v-else>
        <!-- header -->
        <CurvedHeader
          v-if="currentCategory"
          :title="currentCategory.name" />
        <div class="ma-7">
          <!-- search input -->
          <v-row
            class="col-12 px-0"
            no-gutters
            align="center"
            justify="space-between">
            <v-btn
              text
              small
              class="text-none brandGrey2--text"
              @click="$router.push({ name: 'Explore' })">
              <v-icon
                color="primaryColor"
                class="mr-4"
                >mdi-arrow-left</v-icon
              >
              Back to categories
            </v-btn>
            <v-btn
              @click="openCourseFiltersDialog"
              icon>
              <v-img :src="require('@/assets/course/filtersIcon.png')"></v-img>
            </v-btn>
          </v-row>

          <v-row
            class="col-12 px-1"
            no-gutters>
            <v-tabs
              v-model="tab"
              background-color="offWhite"
              color="white"
              slider-size="40">
              <v-tab
                v-for="type in types"
                :key="type"
                class="tab-item offBlack--text text-capitalize"
                :ripple="false">
                <span style="z-index: 5">{{ type }}</span>
              </v-tab>
            </v-tabs>
          </v-row>

          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="type in types"
              :key="type">
              <v-row no-gutters>
                <v-col
                  cols="6"
                  class="pa-1"
                  v-for="course in filteredCourses"
                  :key="course.id">
                  <CourseGridCard :course="course" />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>

      <v-bottom-sheet
        v-model="showCourseFiltersDialog"
        bottom>
        <course-filters-dialog
          :mediaType="mediaType"
          :maxTime="maxTime"
          @closeDialog="closeCourseFiltersDialog"
          @setMediaType="setMediaType"
          @setMaxTime="setMaxTime"
          @filterCourses="filterTabsCourses"
          @clearAllFilters="resetTabsCourses" />
      </v-bottom-sheet>
    </div>
  </transition>
</template>

<script>
  import CoursesByCategoryMixin from '@/views/courses/courses-by-category/CoursesByCategory.mixin.vue';
  export default {
    mixins: [CoursesByCategoryMixin],
  };
</script>

<style scoped>
  ::v-deep .v-tabs-bar {
    padding: 4px;
    border-radius: 12px;
  }
  ::v-deep .v-tabs-slider {
    border-radius: 12px;
    box-shadow: 0 2px 8px 0 rgba(255, 255, 255, 0.08);
  }
  ::v-deep .v-tab::before {
    background-color: transparent;
  }
  .tab-item {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    width: 33%;
  }
</style>
